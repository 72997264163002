import {
  faAdd,
  faEdit,
  faHSquare,
  faTrash,
  faUndoAlt,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Image } from "primereact/image";
import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { TailSpin } from "react-loader-spinner";
import isconnected from "../../../../service/isconnected";
import { toast } from "react-toastify";
import { file_url, motsministres, vehicule } from "../../../../service/config";
import { resizeImage } from "../../../../service/ResizeFile";
import { useSelector } from "react-redux";
import "./StylePhoto.css";
import Vehicules from "./Vehicules";
import Others from "./Others";

export default function MoyenMateriel() {
  const [activeKey, setActiveKey] = useState("vehicule");

  const handleSelect = (key) => {
    console.log(`Active tab changed to: ${key}`);
    setActiveKey(key); // Met à jour l'onglet actif si nécessaire
  };

  return (
    <div>
      <div className="container-lg container-fluid pt-4">
        <div className=" d-flex flex-row align-items-center justify-content-between">
          <div className="">
            <h2>
              <FontAwesomeIcon icon={faHSquare} className="me-1" />
              Moyens matériels{" "}
            </h2>
          </div>
          <div>
            <Tabs
              defaultActiveKey="profile"
              onSelect={handleSelect}
              id="justify-tab-example"
              className="mb-3"
              justify
            >
              <Tab eventKey="vehicule" title="vehicules">
                
              </Tab>
              <Tab eventKey="autres" title="Autres">
                
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <div>
        {activeKey == "vehicule" ? <Vehicules/> : <Others/>}
      </div>
    </div>
  );
}

