import {
    faAdd,
    faCar,
    faEdit,
    faHSquare,
    faTrash,
    faUndoAlt,
    faWarning,
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import axios from "axios";
  import { Image } from "primereact/image";
  import React, { useEffect, useState } from "react";
  import { Button, Modal } from "react-bootstrap";
  import DataTable from "react-data-table-component";
  import { TailSpin } from "react-loader-spinner";
  import isconnected from "../../../../service/isconnected";
  import { toast } from "react-toastify";
  import { file_url, motsministres, vehicule } from "../../../../service/config";
  import { resizeImage } from "../../../../service/ResizeFile";
  import { useSelector } from "react-redux";
  import "./StylePhoto.css";
  
  export default function Vehicules() {
    const [showmodal, setshowmodal] = useState({ del: false, form: false });
    const [selectedRow, setSelectedRows] = useState({});
    const [mode, setmode] = useState(false);
    const [error, setError] = useState({});
    const [formValues, setFormValues] = useState({});
    const [imagescan, setImagescan] = useState([]);
    const [data, setdata] = useState([]);
    const [load, setload] = useState({
      button: false,
      data: true,
    });
    const MonCompte = useSelector((state) => state.compte);
    const abortController = new AbortController();
  
    const columns = [
      {
        name: "Numéro Véhicule",
        selector: "num_veh",
        sortable: true,
      },
      {
        name: "Marque",
        selector: "marque_veh",
        sortable: true,
      },
      {
        name: "Genre",
        selector: "genre_veh",
        sortable: true,
      },
      {
        name: "Type",
        selector: "type_veh",
        sortable: true,
      },
      {
        name: "Puissance",
        selector: "puissance_veh",
        sortable: true,
      },
      {
        name: "Nombre de place",
        selector: "place_veh",
        sortable: true,
      },
      {
        name: "Année",
        selector: "annee_veh",
        sortable: true,
      },
      {
        name: "Date de mise en Circulation",
        selector: "dpmc_veh",
        sortable: true,
      },
      {
        name: "État",
        selector: "etat_veh",
        sortable: true,
      },
      {
        name: "Photos",
        cell: (row) => (
          <div className="photo-container">
            <Image
              src={file_url + row.photo1_veh}
              alt="Vehicule 1"
              className="photo"
              preview
            />
            {row.photo2_veh && (
              <Image
                src={file_url + row.photo2_veh}
                alt="Vehicule 2"
                className="photo"
                preview
              />
            )}
            {row.photo3_veh && (
              <Image
                src={file_url + row.photo3_veh}
                alt="Vehicule 3"
                className="photo"
                preview
              />
            )}
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
      {
        width: "175px",
        name: "Action",
        selector: (row) => (
          <div className="d-flex gap-2">
            <Button
              className="bg-cyan"
              onClick={() => {
                // Filtrer les photos et garder les autres propriétés
                const { ...filtrer } = row;
                const photoKeys = Object.keys(row).filter((key) =>
                  row[key] !=null && key.startsWith("photo")
                );
  
                // Créer un tableau d'URLs pour chaque photo
                const imagescanUrls = photoKeys.map((key) => file_url + row[key]);
  console.log(photoKeys)
                // Mettre à jour les états
                setmode(true);
                setError({});
                setSelectedRows(row);
  
                // Enlever les photos du formulaire et garder le reste
                const filteredFormValues = { ...row };
                photoKeys.forEach((key) => delete filteredFormValues[key]);
  
                setFormValues(filteredFormValues); // Mettre à jour le formulaire sans les photos
                setImagescan(imagescanUrls); // Mettre à jour imagescan avec toutes les URLs des photos
                handleShowModal("form");
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button
              className="bg-danger"
              onClick={() => {
                setSelectedRows(row);
                handleShowModal("del");
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </div>
        ),
        sortable: true,
      },
    ];
  
    const handleShowModal = (mod) => {
      setshowmodal({ ...showmodal, [mod]: !showmodal[mod] });
    };
  
    const handleChange = ({ currentTarget }) => {
      const { name, value } = currentTarget;
  
      setFormValues({ ...formValues, [name]: value });
      setError({ ...error, [name]: null });
    };
  
    const onImageChange = async (e) => {
      try {
        const files = e.target.files;
        console.log(files);
        if (files && files.length > 0) {
          const updatedFormValues = Object.keys(formValues)
            .filter((key) => !key.startsWith("photo"))
            .reduce((obj, key) => {
              obj[key] = formValues[key];
              return obj;
            }, {});
  
          for (let i = 0; i < files.length; i++) {
            const resizedImage = await resizeImage(files[i]);
            const photoKey = `photo${i + 1}_veh`;
  
            updatedFormValues[photoKey] = resizedImage;
          }
          setFormValues(updatedFormValues);
          setImagescan(
            Object.keys(updatedFormValues)
              .filter((key) => key.startsWith("photo"))
              .map((key) => URL.createObjectURL(updatedFormValues[key]))
          );
          console.log(imagescan);
        }
      } catch (error) {
        console.error("Error", error);
      }
    };
  
    const getdata = () => {
      setload({ ...load, data: true });
      axios
        .get(vehicule, {
          signal: abortController.signal,
        })
        .then((rep) => {
          console.log(rep.data);
          setdata(rep.data.data);
          setload({ ...load, data: false });
        })
        .catch((err) => {
          setload({ ...load, data: false });
          console.log(err);
        });
    };
  
    const handleSubmit = async () => {
      formValues.id_org = MonCompte.id_org;
      const apiErrors = {};
      setload({ ...load, button: true });
      console.log(formValues);
      axios
        .post(vehicule, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((rep) => {
          toast.success("opération réussi !");
          setload({ ...load, button: false });
          handleShowModal("form");
          getdata();
        })
        .catch((error) => {
          console.log(error);
          const msg = error.response.data;
          setload({ ...load, button: false });
          setload(false);
          if (msg) {
            toast.error("Verifier votre saisi !");
            let champs = Object.keys(msg);
            for (let i = 0; i < champs.length; i++) {
              const element = champs[i];
              if (msg[element]) {
                apiErrors[element] = msg[element];
              }
            }
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
          setError(apiErrors);
        });
    };
    const handleUpdate = async () => {
      const apiErrors = {};
  
      setload({ ...load, button: true });
      console.log(formValues);
      await axios
        .post(vehicule + `/${formValues.id_veh}?_method=PUT`, formValues, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((rep) => {
          toast.success("opération réussi !");
          setload({ ...load, button: false });
          handleShowModal("form");
          getdata();
        })
        .catch((error) => {
          console.log(formValues);
          console.log(error);
          const msg = error.response.data;
          setload({ ...load, button: false });
          setload(false);
          if (msg) {
            toast.error("Verifier votre saisi !");
            let champs = Object.keys(msg);
            for (let i = 0; i < champs.length; i++) {
              const element = champs[i];
              if (msg[element]) {
                apiErrors[element] = msg[element];
              }
            }
          }
          if (error.response.status == 500) {
            toast.error(error.response.data.message);
          }
          if (error.response.status == 401) {
            toast.info(error.response.data.message);
          }
          setError(apiErrors);
        });
    };
  
    const handledelete = async () => {
      setload({ ...load, button: true });
      try {
        axios
          .delete(vehicule + "/" + selectedRow.id_veh)
          .then(() => {
            toast.success("Element supprimé");
            getdata();
            setload({ ...load, button: false });
            handleShowModal("del");
          })
          .catch((e) => {
            console.log(e);
            toast.error("Un erreur est survenu !");
            handleShowModal("del");
            setload({ ...load, button: false });
          });
      } catch (error) {
        toast.error("Un erreur est survenu !");
        handleShowModal("del");
        setload({ ...load, button: false });
      }
    };
  
    useEffect(() => {
      getdata();
  
      return () => {
        abortController.abort();
      };
    }, []);
    return (
      <div>
        <Modal
          show={showmodal.del}
          size="sm"
          onHide={() => handleShowModal("del")}
        >
          <Modal.Header>
            <FontAwesomeIcon icon={faWarning} className="bg-danger me-2" />
            Supprimer
          </Modal.Header>
          <Modal.Body>Vous êtes sur de vouloir supprimer ?</Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-2">
              <Button variant="secondary" onClick={() => handleShowModal("del")}>
                Annuler
              </Button>
              <Button
                variant="danger"
                disabled={load.button}
                onClick={() => handledelete()}
              >
                oui, je confirme
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={showmodal.form} size="xl" animation>
          <Modal.Header>
            <FontAwesomeIcon className="bg-danger me-2" />
            Materiels
          </Modal.Header>
          <Modal.Body>
            <form>
              <div className="row align-items-center ">
                <div className="col-12 col-lg-6 flex flex-column align-self-stretch">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="my-3">
                        <label className="form-label">Numéro Véhicule</label>
                        <input
                          className={
                            "form-control " +
                            (error.num_veh?.length > 0 && "is-invalid")
                          }
                          name="num_veh"
                          value={formValues.num_veh}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir le numéro du véhicule"
                        />
                        {error.num_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.num_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <label className="form-label">Marque</label>
                        <input
                          className={
                            "form-control " +
                            (error.marque_veh?.length > 0 && "is-invalid")
                          }
                          name="marque_veh"
                          value={formValues.marque_veh}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir la marque"
                        />
                        {error.marque_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.marque_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <label className="form-label">Genre</label>
                        <input
                          className={
                            "form-control " +
                            (error.genre_veh?.length > 0 && "is-invalid")
                          }
                          name="genre_veh"
                          value={formValues.genre_veh}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir le genre du véhicule"
                        />
                        {error.genre_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.genre_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <label className="form-label">Type</label>
                        <input
                          className={
                            "form-control " +
                            (error.type_veh?.length > 0 && "is-invalid")
                          }
                          name="type_veh"
                          value={formValues.type_veh}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir le type du véhicule"
                        />
                        {error.type_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.type_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <label className="form-label">Puissance</label>
                        <input
                          className={
                            "form-control " +
                            (error.puissance_veh?.length > 0 && "is-invalid")
                          }
                          name="puissance_veh"
                          value={formValues.puissance_veh}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir la puissance du véhicule"
                        />
                        {error.puissance_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.puissance_veh[0]}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 flex flex-column align-self-stretch">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="my-3">
                        <label className="form-label">Nombre de place</label>
                        <input
                          className={
                            "form-control " +
                            (error.place_veh?.length > 0 && "is-invalid")
                          }
                          name="place_veh"
                          value={formValues.place_veh}
                          onChange={handleChange}
                          type="number"
                          placeholder="Saisir le nombre de place"
                        />
                        {error.place_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.place_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <label className="form-label">Année</label>
                        <input
                          className={
                            "form-control " +
                            (error.annee_veh?.length > 0 && "is-invalid")
                          }
                          name="annee_veh"
                          value={formValues.annee_veh}
                          onChange={handleChange}
                          type="number"
                          placeholder="Saisir l'année"
                        />
                        {error.annee_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.annee_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <label className="form-label">
                          Date de mise en circulation
                        </label>
                        <input
                          className={
                            "form-control " +
                            (error.dpmc_veh?.length > 0 && "is-invalid")
                          }
                          name="dpmc_veh"
                          value={formValues.dpmc_veh}
                          onChange={handleChange}
                          type="date"
                        />
                        {error.dpmc_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.dpmc_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <label className="form-label">État</label>
                        <input
                          className={
                            "form-control " +
                            (error.etat_veh?.length > 0 && "is-invalid")
                          }
                          name="etat_veh"
                          value={formValues.etat_veh}
                          onChange={handleChange}
                          type="text"
                          placeholder="Saisir l'état du véhicule"
                        />
                        {error.etat_veh?.length > 0 && (
                          <div className="invalid-feedback">
                            {error.etat_veh[0]}
                          </div>
                        )}
                      </div>
  
                      <div className="my-3">
                        <div className="row">
                          <div className="col-12">
                            <label className="form-label">
                              Photo du véhicule
                            </label>
                            <input
                              className={
                                "form-control " +
                                (error.image?.length > 0 && "is-invalid")
                              }
                              name="image"
                              id="image"
                              type="file"
                              max={5}
                              onChange={onImageChange}
                              accept="image/*"
                              multiple
                            />
                            {error.image?.length > 0 && (
                              <div className="invalid-feedback">
                                {error.image[0]}
                              </div>
                            )}
                          </div>
                          {imagescan?.length > 0 && (
                            <div className="d-flex gap-2 mt-2">
                              {imagescan.map((el, i) => (
                                <div className="">
                                  <Image
                                    src={el}
                                    key={i}
                                    alt="Aperçu du véhicule"
                                    width="70"
                                    preview
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-2">
              <Button variant="secondary" onClick={() => handleShowModal("form")}>
                Annuler
              </Button>
              <Button
                variant="primary"
                disabled={load.button}
                onClick={() => (mode ? handleUpdate() : handleSubmit())}
              >
                {mode ? "Modifier" : "Enregistrer"}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <div className="container-lg container-fluid py-4">
          <div className="mb-3 d-flex flex-row align-items-center justify-content-between">
            <div className="">
              <h2>
                <FontAwesomeIcon icon={faCar} className="me-1" />
                Véhicules{" "}
              </h2>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  setmode(false);
                  setImagescan(null);
                  setFormValues({});
                  setError({});
                  handleShowModal("form");
                }}
              >
                <FontAwesomeIcon icon={faAdd} className="me-2" />
                Ajouter
              </Button>
            </div>
          </div>
          <div className="card mt-3">
            <div className="card-body">
              <div className="">
                <DataTable
                  className="custom-table"
                  columns={columns}
                  data={data}
                  progressPending={load.data}
                  progressComponent={
                    <div className="w-100 d-flex justify-content-center py-5">
                      <TailSpin
                        height="80"
                        width="80"
                        color="#22577A"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                      />
                    </div>
                  }
                  contextMessage={{
                    singular: "élement",
                    plural: "élements",
                    message: "sélectionnées",
                  }}
                  pagination
                  paginationPerPage={5}
                  paginationComponentOptions={{
                    rowsPerPageText: "Lignes par page",
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  