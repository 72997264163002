import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import moment from "moment";
import PrintResTheorie from "../../../../service/Print/PrintResTheorie";
import SelectFinalOne from "../../../../outils/Select/SelectFinalOne";
import isconnected from "../../../../service/isconnected";

export default function DetailExamen({
  list,
  cherche,
  Auto,
  choix,
  error,
  setError,
  setfiltre,
  filtre,
  Affiche
}) {
  const columns = [
    {
      name: "ID Candidat",
      selector: (row) => row?.unique_id_cand || "En attente mis a jour",
      sortable: true,
    },
    {
      name: "Nom et Prenom",
      selector: (row) => (
        <div title={row.nom_cand + " " + row.prenom_cand}>
          {row.nom_cand + " " + row.prenom_cand}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Centre",
      width: "170px",
      selector: (row) => (
        <div title={list?.examen?.organisme.nom_org}>
          {list?.examen?.organisme.nom_org}
        </div>
      ),
      sortable: true,
    },

    {
      name: "Auto-école",
      selector: (row) => {
        return row.nom_org;
      },
      sortable: true,
    },

    {
      name: "Date de l'Examen",
      width: "200px",
      selector: (row) => (
        <div title={moment(list?.examen?.date_exam).locale("fr").format("LL")}>
          {moment(list?.examen?.date_exam).locale("fr").format("LL")}
        </div>
      ),
      sortable: true,
    },
    {
      name: "Heure de l'Examen",
      width: "200px",
      selector: (row) => (
        <div title={list?.examen?.heurs_exam}>{list?.examen?.heurs_exam}</div>
      ),
      sortable: true,
    },
    {
      width: "120px",
      name: "Note",
      selector: (row) => row?.note_examen + "/" + row?.note_sur,
      sortable: true,
    },
  ];

  const [examodal, setexamodal] = useState(false);
  const dataRedux = useSelector((state) => state.listtypeexam);
  const Type = (id) => {
    console.log(dataRedux);
    const element = dataRedux.find((element) => element.value == id);

    if (element !== undefined) {
      return `${element.label}`;
    } else {
      return `${id}`;
    }
  };
  return (
    <div>
      <div className="">
        <Button variant="info" onClick={() => setexamodal(!examodal)}>
          Les candidats
        </Button>
      </div>
      <Modal
        autoFocus={true}
        backdrop="static"
        fullscreen={true}
        size="xl"
        show={examodal}
        onHide={() => setexamodal(!examodal)}
      >
        <Modal.Header className="h1" closeButton>
          Liste des candidats {Affiche ? "redoublants " : "admis"}
        </Modal.Header>
        <Modal.Body>
          <div className="row">
          <div
                className="col-lg-6 col-md-6 col-12"
                style={{ position: "relative" }}
              >
                <div style={{ height: "100px" }}>
                {isconnected.user().type_util !== "Auto-écoles" && (
                  <div
                    className="my-2"
                    style={{ position: "absolute", zIndex: "999999" }}
                  >
                    <label className="form-label">Auto-école</label>
                    <SelectFinalOne
                      options={Auto}
                      load={false}
                      value={"label"}
                      label={"label"}
                      choix={choix}
                      placeholder="Filtre par auto-école"
                      setFormValues={setfiltre}
                      formValues={filtre}
                      setError={setError}
                      error={error}
                      name={"auto"}
                      seteventy={null}
                    />
                  </div>
                )}
                </div>
              </div>
          <div className="col-6 d-flex justify-content-end">
              <div>
                {list?.candidats?.length > 0 && (
                  <PrintResTheorie
                  Affiche={Affiche}
                    list={{
                      ...list,
                      candidats:
                        filtre?.auto
                          ? list.candidats.filter((f) =>
                              f?.nom_org !== null
                                ? f.nom_org === filtre.auto
                                : true
                            )
                          : list?.candidats, // Ne filtre pas si la condition n'est pas remplie
                    }}
                    auto={Auto}
                    cherche={cherche}
                  />
                )}
              </div>
            </div>
           
          
          
          
          </div>

          <DataTable
            noContextMenu
            className="custom-table"
            columns={columns}
            data={list.candidats.filter((f) =>
              filtre.auto != undefined && f?.nom_org != null
                ? f.nom_org == filtre.auto
                : f
            )}
            highlightOnHover
            responsive
            progressComponent={
              <div className="w-100 d-flex justify-content-center py-5">
                <TailSpin
                  height="80"
                  width="80"
                  color="#22577A"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            }
            contextMessage={undefined}
            contextActions={null}
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "Lignes par page",
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20]}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
