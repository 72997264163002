import React, { useState } from "react";
import "./Print.css";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faFileExcel, faPrint } from "@fortawesome/free-solid-svg-icons";
import moment from "moment/moment";
import "moment/locale/fr";
import Cherche from "../../outils/Cherche";
import XlsPrint from "./XlsPrint";
import { write, utils } from "xlsx";
import { saveAs } from "file-saver";

export default function PrintResPratique({
  list,
  auto,
  cherche,
  Affiche
}) {
  const ref = React.useRef();
  const [open, setopen] = useState(false);

  const exportToExcel = () => {
    // Structure des données de l'examen (mise sous forme de tableau de tableaux)
    const examData = [
      ["Centre :", list.examen.organisme.nom_org],
      ["Date d'examen :", moment(list?.examen?.date_examp).locale("fr").format("LL")],
    ];

    // Structure des données des candidats (mise sous forme de tableau de tableaux)
    const candidatsData = list.candidats.map((candidat) => [
      candidat?.unique_id_cand,
      candidat?.nom_cand,candidat?.prenom_cand,
    candidat?.nom_org,
      `${candidat?.note_examen}/${candidat?.note_sur}`,
      "Admis", // Vous pouvez modifier cette valeur si nécessaire
    ]);

    // Titre principal de la feuille
    const title = [["Résultat pour l'examen pratique"]];

    // Combinez les données de l'examen et les candidats dans une seule structure
    const combinedData = [
      ...title, // Titre principal
      ...examData, // Données de l'examen
      [],
      [], // Ligne vide pour séparer les sections
      ["Liste des candidats"], // Titre pour les candidats
      ["ID", "Nom","Prénom", "Auto-école", "Note", "Résultat final"], // En-têtes pour les candidats
      ...candidatsData // Données des candidats
    ];

    // Crée une feuille Excel avec les données combinées
    const ws = utils.aoa_to_sheet(combinedData);

   

    // Crée un workbook et y ajoute la feuille
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Résultat examen");

    // Convertit le workbook en un fichier binaire
    const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });

    // Création du Blob et sauvegarde
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "resultats_examen_pratique.xlsx");
  };

  return (
    <>
     <div className="d-flex gap-3">
     <ReactToPrint
        trigger={() => (
          <Button variant="success">
            <FontAwesomeIcon icon={faPrint} className="me-2" />
            Imprimer
          </Button>
        )}
        content={() => ref.current}
      />
      <Button variant="info" onClick={()=>exportToExcel()}>
            <FontAwesomeIcon icon={faFileExcel}  className="me-2" />
            Export en excel
      </Button>
     
     </div>
      <div className="d-none">
        <div className="row justify-content-center">
          <div className="col-6">
            <div ref={ref}>
              <div>
                <h1 className="mb-4"> Résultat pour l'examen pratique</h1>
                <div className="text-wrap fs-4 my-3">
                 
                    <React.Fragment>
                      <span className="py-5 fw-bold">Auto-école: </span> {list.examen.organisme.nom_org}{" "}
                      <br />
                      <span className="py-5 fw-bold">Date d'examen: </span>
                      {moment(list?.examen?.date_examp)
                        .locale("fr")
                        .format("LL")}{" "}
                      <br />
                    
                    </React.Fragment>
                
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Nom & Prénom</th>
                      <th>Auto-école</th>
                      <th>Note</th>
                      <th>Resultat final</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.candidats.map((candidat) => (
                      <tr key={candidat?.unique_id_cand}>
                        <td>{candidat?.unique_id_cand}</td>
                        <td>
                          {candidat?.nom_cand +
                            " " +
                            candidat?.prenom_cand}
                        </td>
                        <td>{cherche(auto,candidat,"id_org","value","label")}</td> 
                        <td>
                          {candidat?.note_examen + "/" + candidat?.note_sur}
                        </td>
                        <td>{Affiche ? "Reboublant" : "Admis"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={open}
        onHide={() => setopen(!open)}
        animation
        scrollable
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body></Modal.Body>
      </Modal>
    </>
  );
}
